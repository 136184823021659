import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import login from '../redux/actions/login';
import { InputText } from 'primereact/inputtext';
import Button from '../components/Button';
import logo from '../logo.png';
import Input from '../components/Input';

const LoginPanel = () => {

    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(login({ email, password }));
    };

    return (
        <div className="flex align-items-center justify-content-center" style={{ height: '100vh', width: '100vw' }}>
                <div className="surface-card p-4 w-10 md:w-6 lg:w-4 shadow-2 border-round w-full">
                <div className="text-center mb-3">
                    <img height={100} src={logo} alt="logo" />
                </div>

                <div>
                    <Input value={email} onChange={(e) => setEmail(e.target.value)} id="email" type="text" placeholder="Adresse email" label="Email"  />
                    <Input value={password} onChange={(e) => setPassword(e.target.value)} id="password" type="password" label="Mot de passe" placeholder="Mot de passe" className="w-full mb-3" />
                    <Button onClick={handleSubmit} label="Se connecter"/>
                </div>
            </div>
        </div>
    );
}

export default LoginPanel;
