import axios from 'axios';
import { PICANOVA_ORDERS_FAILED, PICANOVA_ORDERS_SUCCESS } from '../constants';

export const getPicanovaOrders = () => {
    return async (dispatch) => {
        try {
            const { data } = await axios.get('/picanova/orders');
            dispatch({ type: PICANOVA_ORDERS_SUCCESS, payload: data });
        } catch (error) {
            dispatch({ type: PICANOVA_ORDERS_FAILED, payload: error });
        }
    };
};
