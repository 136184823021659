import Axios from 'axios';
import AuthenticationService from './authentication';

Axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/`;

Axios.interceptors.request.use(async (config) => {
    // Call the refreshToken method. This method should return the valid token.
    const token = await AuthenticationService.refreshToken();

    // Set the common header only if the token is available
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    // Set Content-Type header for JSON data
    config.headers['Content-Type'] = 'application/json';

    // Stringify data if it's not FormData
    if (!(config.data instanceof FormData)) {
        config.data = JSON.stringify(config.data);
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});


Axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response) {
        // Consider handling all 401 statuses in a consistent manner
        if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/');
        }

        if (error.response.status === 403) {
            // Handle 403 Forbidden response if needed
        }
    }

    return Promise.reject(error);
});
