import axios from 'axios';
import { LOGIN_FAILED, LOGIN_SUCCESS } from '../constants';
import AuthenticationService from '../../services/authentication';

const login = (credentials) => {
    return async (dispatch) => {
        try {
            const { data } = await axios.post('/auth/login', credentials);
            AuthenticationService.login(data.user, data.token);
            window.location.href = '/dashboard';
        } catch (error) {
            dispatch({ type: LOGIN_FAILED, payload: error });
        }
    };
};

export default login;
