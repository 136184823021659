import { InputText } from 'primereact/inputtext';


const Input = ({id, value, name, type, placeholder, onChange, required, label}) => {
    return (
        <>
            <label htmlFor={name} className="block text-900 font-medium mb-2">{label}</label>
            <InputText
                id={id}
                name={name}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                className="w-full mb-3"
                required={required}
            />
        </>
    );
}

export default Input;