import LoginPanel from './pages/LoginPanel';
import DashboardPanel from './pages/DashboardPanel';
import IsLoggedHOC from './hoc/isLoggedHoc';
import { Navigate } from 'react-router-dom';
export const routes = [
  {
    path: '/',
    component: LoginPanel,
    exact: true,
  },
  {
    path: '/dashboard',
    component: IsLoggedHOC(DashboardPanel),
    exact: true,
  },
  {
    path: '*',
    component: () => <Navigate to="/" replace />,
  }
];
