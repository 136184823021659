import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthenticationService from '../services/authentication';

const IsLoggedHOC = (WrappedComponent, mustBeLogged = true) => {
    return (props) => {
        const isAuthenticated = AuthenticationService.getSession();

        if (mustBeLogged && !isAuthenticated) {
            return <Navigate to="/" replace />;
        }

        if (!mustBeLogged && isAuthenticated) {
            return <Navigate to="/dashboard" replace />;
        }

        return <WrappedComponent {...props} />;
    };
};

export default IsLoggedHOC;
