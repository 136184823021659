import Axios from 'axios';
import {jwtDecode} from "jwt-decode";

class AuthenticationService {
    static BROWSER_ID = null;

    static setAuthenticationStorage = (accessToken, refreshToken = null) => {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
    }

    static isLogged = () => {
        return localStorage.getItem('user') !== null
    }

    static setUserData = (user) => {
        localStorage.setItem('user', JSON.stringify(user));
    }

    static setToken = (token) => {
        const { accessToken, refreshToken } = token;
        this.setAuthenticationStorage(accessToken, refreshToken);
    }

    static login = (user, token) => {
        this.setToken(token);
        this.setUserData(user);
    }

    static logout = () => {
        const refreshToken = localStorage.getItem('refreshToken');
        Axios.post('user/logout', { refreshToken: refreshToken })
            .finally(() => {
                localStorage.clear();
                window.location.href = '/';
            });
    }

    static getToken = () => {
        return localStorage.getItem('accessToken');
    }


    static refreshToken = async () => {
        const refreshToken = localStorage.getItem('refreshToken');
        const accessToken = localStorage.getItem('accessToken');
        const user = localStorage.getItem('user');

        if (!refreshToken || !user) {
            localStorage.clear();
            return false;
        }

        // Decode the token to get expiry date and other information
        const decodedToken = jwtDecode(accessToken);
        if (!decodedToken) {
            localStorage.clear();
            return false;
        }

        // Check if the token is about to expire (10 seconds before expiry)
        if (!decodedToken.exp || new Date(decodedToken.exp * 1000 - 10000) <= new Date()) {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/refresh`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ refreshToken })
            });
        
            if (response.ok) {
                const { accessToken, refreshToken } = await response.json();
                AuthenticationService.setAuthenticationStorage(accessToken, refreshToken);
                return accessToken;
            } else {
                localStorage.clear();
                return false;
            }
        }
        

    return accessToken;
}


    static getSession = () => {
        return JSON.parse(localStorage.getItem('user'));
    }

    static getRefreshToken = () => {
        return localStorage.getItem('refreshToken');
    }
}

export default AuthenticationService