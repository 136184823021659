import React from "react";
import { Button as PrimeButton } from "primereact/button";
import '../styles/button.css';

const Button = ({ onClick, label, icon }) => {
    return (
        <PrimeButton 
            style={{ backgroundColor: "#56aa34", borderStyle: 'none' }} 
            onClick={onClick}
            label={label} 
            icon={icon} 
            className="w-full button" 
        />
    );
}

export default Button;
