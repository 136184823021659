import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getPicanovaOrders } from "../redux/actions/picanova";

const DashboardPanel = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPicanovaOrders());
    }, []);

    return (
        <div>
            <h1>DashboardPanel</h1>
        </div>
    )
}

export default DashboardPanel;