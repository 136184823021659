import { LOGIN_FAILED, LOGIN_SUCCESS } from "../constants";

const initialState = {
    user: null,
    error: null
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return { ...state, user: action.payload, error: null };
        case LOGIN_FAILED:
            return { ...state, error: action.payload };
        default:
            return state;
    }
};

export default authReducer;
